"use client";
import { useResolvedTheme } from "@kamio/design-system/components/theme/switcher/hooks";
import { motion, Variants } from "framer-motion";
import { ComponentProps } from "react";

import { cn } from "@/lib/utils";

const KamioKashLogo = ({ className, ...props }: ComponentProps<typeof motion.svg>) => {
  const mode = useResolvedTheme();

  const icon: Variants = {
    hidden: {
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      pathLength: 1,
      fill: mode === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
    },
  };

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 600"
      className={cn("text-black dark:text-white", className)} // Use Tailwind classes for color control
      {...props}
    >
      <motion.path
        d="M239.008 251.43C224.955 240.316 206.25 230.318 184.802 230.318V197.998C216.359 197.998 242.007 212.596 259.056 226.079C265.467 231.149 270.851 236.207 275.094 240.57C279.338 236.207 284.721 231.149 291.132 226.079C308.181 212.596 333.829 197.998 365.386 197.998V230.318C343.939 230.318 325.233 240.316 311.18 251.43C304.257 256.905 298.785 262.401 295.066 266.51C293.215 268.554 291.82 270.23 290.919 271.352C290.47 271.913 290.145 272.333 289.949 272.589C289.852 272.718 289.786 272.805 289.754 272.848L289.738 272.87L289.731 272.879C288.721 274.271 289.715 276.221 291.435 276.221L367.029 276.221V318.863L282.946 395.635H267.185L183.102 318.863V276.221L258.753 276.221C260.473 276.221 261.467 274.271 260.457 272.879L260.451 272.871L260.434 272.848C260.402 272.805 260.337 272.718 260.239 272.589C260.044 272.333 259.719 271.913 259.269 271.352C258.368 270.23 256.974 268.554 255.122 266.51C251.403 262.401 245.932 256.905 239.008 251.43ZM219.73 308.541H330.401L275.065 359.065L219.73 308.541Z"
        fillRule="evenodd"
        clipRule="evenodd"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M1702.55 394.332V323.568C1702.55 318.941 1702.23 313.816 1701.6 308.191C1700.96 302.566 1699.46 297.168 1697.11 291.997C1694.84 286.735 1691.39 282.426 1686.76 279.069C1682.23 275.712 1676.06 274.034 1668.26 274.034C1664.08 274.034 1659.96 274.714 1655.87 276.075C1651.79 277.436 1648.07 279.795 1644.71 283.151C1641.45 286.417 1638.82 290.953 1636.82 296.76C1634.83 302.475 1633.83 309.824 1633.83 318.805L1614.37 310.504C1614.37 297.984 1616.77 286.644 1621.58 276.483C1626.48 266.322 1633.65 258.248 1643.08 252.26C1652.52 246.182 1664.13 243.143 1677.92 243.143C1688.81 243.143 1697.79 244.957 1704.86 248.586C1711.94 252.215 1717.56 256.842 1721.74 262.467C1725.91 268.091 1729 274.079 1730.99 280.43C1732.99 286.78 1734.26 292.813 1734.8 298.529C1735.44 304.154 1735.75 308.735 1735.75 312.273V394.332H1702.55ZM1600.62 394.332V198.371H1629.88V301.523H1633.83V394.332H1600.62Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M1524 398.414C1505.86 398.414 1491.11 394.332 1479.77 386.167C1468.43 378.002 1461.54 366.525 1459.09 351.737L1492.57 346.566C1494.29 353.824 1498.1 359.54 1504 363.713C1509.89 367.886 1517.33 369.973 1526.31 369.973C1534.21 369.973 1540.29 368.43 1544.55 365.346C1548.9 362.17 1551.08 357.861 1551.08 352.418C1551.08 349.061 1550.27 346.385 1548.63 344.389C1547.09 342.302 1543.64 340.306 1538.29 338.401C1532.94 336.496 1524.73 334.092 1513.66 331.189C1501.32 327.923 1491.52 324.43 1484.27 320.71C1477.01 316.9 1471.79 312.409 1468.62 307.238C1465.44 302.067 1463.85 295.807 1463.85 288.458C1463.85 279.295 1466.26 271.312 1471.06 264.508C1475.87 257.703 1482.59 252.487 1491.21 248.858C1499.82 245.138 1509.98 243.279 1521.69 243.279C1533.12 243.279 1543.23 245.048 1552.03 248.586C1560.93 252.124 1568.09 257.159 1573.54 263.691C1578.98 270.223 1582.34 277.889 1583.61 286.689L1550.13 292.677C1549.31 286.417 1546.46 281.473 1541.56 277.844C1536.75 274.215 1530.31 272.174 1522.23 271.72C1514.52 271.266 1508.31 272.446 1503.59 275.258C1498.87 277.98 1496.51 281.836 1496.51 286.825C1496.51 289.638 1497.47 292.042 1499.37 294.038C1501.28 296.034 1505.09 298.03 1510.8 300.026C1516.61 302.021 1525.23 304.471 1536.66 307.374C1548.36 310.368 1557.7 313.815 1564.69 317.716C1571.77 321.527 1576.85 326.108 1579.93 331.461C1583.11 336.814 1584.69 343.3 1584.69 350.921C1584.69 365.709 1579.3 377.321 1568.5 385.758C1557.8 394.196 1542.96 398.414 1524 398.414Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M1365.14 398.414C1354.53 398.414 1345.55 396.418 1338.2 392.426C1330.85 388.344 1325.27 382.946 1321.46 376.232C1317.74 369.519 1315.88 362.125 1315.88 354.051C1315.88 346.974 1317.06 340.624 1319.42 334.999C1321.78 329.284 1325.41 324.385 1330.31 320.302C1335.21 316.129 1341.56 312.727 1349.36 310.096C1355.26 308.191 1362.15 306.467 1370.04 304.925C1378.03 303.382 1386.65 301.976 1395.9 300.706C1405.24 299.345 1415 297.894 1425.16 296.351L1413.45 303.019C1413.55 292.858 1411.28 285.374 1406.65 280.566C1402.02 275.757 1394.22 273.353 1383.24 273.353C1376.62 273.353 1370.23 274.895 1364.06 277.98C1357.89 281.064 1353.58 286.372 1351.13 293.902L1321.19 284.512C1324.82 272.083 1331.71 262.103 1341.87 254.574C1352.13 247.044 1365.92 243.279 1383.24 243.279C1396.31 243.279 1407.78 245.411 1417.67 249.674C1427.65 253.938 1435.05 260.924 1439.85 270.631C1442.49 275.803 1444.07 281.11 1444.62 286.553C1445.16 291.906 1445.43 297.757 1445.43 304.108V394.332H1416.72V362.488L1421.48 367.659C1414.86 378.274 1407.1 386.076 1398.21 391.066C1389.41 395.965 1378.39 398.414 1365.14 398.414ZM1371.68 372.286C1379.12 372.286 1385.47 370.971 1390.73 368.34C1395.99 365.709 1400.16 362.488 1403.25 358.678C1406.42 354.867 1408.56 351.284 1409.64 347.927C1411.37 343.754 1412.32 338.991 1412.5 333.638C1412.77 328.195 1412.91 323.795 1412.91 320.438L1422.98 323.432C1413.09 324.974 1404.61 326.335 1397.53 327.514C1390.46 328.694 1384.38 329.828 1379.3 330.917C1374.22 331.914 1369.73 333.049 1365.83 334.319C1362.01 335.679 1358.79 337.267 1356.16 339.082C1353.53 340.896 1351.49 342.983 1350.04 345.341C1348.68 347.7 1348 350.467 1348 353.643C1348 357.271 1348.91 360.492 1350.72 363.305C1352.53 366.026 1355.17 368.204 1358.61 369.837C1362.15 371.47 1366.51 372.286 1371.68 372.286Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M1164.16 394.332V198.371H1196.96V289.275L1275.21 198.371H1315.49L1231.25 294.446L1321.2 394.332H1279.56L1196.96 302.883V394.332H1164.16Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M1077.16 398.414C1062.46 398.414 1049.62 395.103 1038.65 388.48C1027.67 381.857 1019.14 372.74 1013.06 361.127C1007.07 349.424 1004.08 335.997 1004.08 320.846C1004.08 305.423 1007.17 291.906 1013.33 280.293C1019.5 268.681 1028.08 259.609 1039.05 253.077C1050.03 246.545 1062.73 243.279 1077.16 243.279C1091.95 243.279 1104.83 246.59 1115.81 253.213C1126.78 259.835 1135.31 268.998 1141.39 280.702C1147.47 292.314 1150.51 305.696 1150.51 320.846C1150.51 336.088 1147.42 349.56 1141.25 361.263C1135.18 372.876 1126.65 381.993 1115.67 388.616C1104.69 395.148 1091.86 398.414 1077.16 398.414ZM1077.16 367.659C1090.22 367.659 1099.93 363.305 1106.28 354.595C1112.63 345.886 1115.81 334.636 1115.81 320.846C1115.81 306.603 1112.59 295.263 1106.14 286.825C1099.7 278.297 1090.04 274.033 1077.16 274.033C1068.36 274.033 1061.1 276.029 1055.38 280.021C1049.76 283.922 1045.59 289.411 1042.86 296.487C1040.14 303.473 1038.78 311.593 1038.78 320.846C1038.78 335.09 1042 346.475 1048.44 355.003C1054.98 363.441 1064.55 367.659 1077.16 367.659Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M957.738 225.043V194.969H990.534V225.043H957.738ZM957.738 394.332V247.361H990.534V394.332H957.738Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M905.778 394.33V305.059C905.778 294.989 903.283 287.142 898.293 281.517C893.304 275.801 886.545 272.944 878.017 272.944C872.755 272.944 868.037 274.168 863.864 276.618C859.691 278.977 856.38 282.515 853.93 287.232C851.48 291.859 850.256 297.393 850.256 303.835L835.695 295.261C835.604 285.1 837.872 276.164 842.499 268.453C847.217 260.651 853.567 254.572 861.551 250.218C869.534 245.863 878.425 243.686 888.223 243.686C904.462 243.686 916.891 248.585 925.51 258.383C934.22 268.09 938.574 280.882 938.574 296.758V394.33H905.778ZM729.005 394.33V247.36H757.991V292.948H762.073V394.33H729.005ZM817.596 394.33V305.468C817.596 295.216 815.101 287.232 810.111 281.517C805.121 275.801 798.317 272.944 789.698 272.944C781.352 272.944 774.639 275.801 769.558 281.517C764.568 287.232 762.073 294.672 762.073 303.835L747.376 294.037C747.376 284.511 749.735 275.937 754.453 268.317C759.17 260.696 765.521 254.708 773.504 250.354C781.579 245.908 790.606 243.686 800.585 243.686C811.563 243.686 820.726 246.044 828.074 250.762C835.513 255.389 841.047 261.739 844.676 269.814C848.396 277.797 850.256 286.824 850.256 296.894V394.33H817.596Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M632.61 398.414C621.996 398.414 613.014 396.418 605.666 392.426C598.317 388.344 592.738 382.946 588.927 376.232C585.208 369.519 583.348 362.125 583.348 354.051C583.348 346.974 584.527 340.624 586.886 334.999C589.245 329.284 592.874 324.385 597.773 320.302C602.672 316.129 609.022 312.727 616.824 310.096C622.721 308.191 629.616 306.467 637.509 304.925C645.493 303.382 654.111 301.976 663.365 300.706C672.71 299.345 682.462 297.894 692.623 296.351L680.92 303.019C681.011 292.858 678.743 285.374 674.116 280.566C669.489 275.757 661.687 273.353 650.709 273.353C644.087 273.353 637.691 274.895 631.521 277.98C625.352 281.064 621.043 286.372 618.594 293.902L588.655 284.512C592.284 272.083 599.179 262.103 609.34 254.574C619.591 247.044 633.381 243.279 650.709 243.279C663.773 243.279 675.25 245.411 685.139 249.674C695.118 253.938 702.512 260.924 707.32 270.631C709.951 275.803 711.539 281.11 712.083 286.553C712.627 291.906 712.9 297.757 712.9 304.108V394.332H684.186V362.488L688.949 367.659C682.326 378.274 674.569 386.076 665.679 391.066C656.878 395.965 645.856 398.414 632.61 398.414ZM639.142 372.286C646.581 372.286 652.932 370.971 658.194 368.34C663.456 365.709 667.629 362.488 670.714 358.678C673.889 354.867 676.021 351.284 677.11 347.927C678.833 343.754 679.786 338.991 679.967 333.638C680.24 328.195 680.376 323.795 680.376 320.438L690.446 323.432C680.557 324.974 672.074 326.335 664.998 327.514C657.922 328.694 651.843 329.828 646.763 330.917C641.682 331.914 637.192 333.049 633.291 334.319C629.48 335.679 626.26 337.267 623.629 339.082C620.998 340.896 618.956 342.983 617.505 345.341C616.144 347.7 615.464 350.467 615.464 353.643C615.464 357.271 616.371 360.492 618.185 363.305C620 366.026 622.631 368.204 626.078 369.837C629.616 371.47 633.971 372.286 639.142 372.286Z"
      />
      <motion.path
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        variants={icon}
        d="M431.626 394.332V198.371H464.422V289.275L542.671 198.371H582.951L498.715 294.446L588.667 394.332H547.025L464.422 302.883V394.332H431.626Z"
      />
    </motion.svg>
  );
};

export default KamioKashLogo;
