import { _ as _extends } from "@swc/helpers/_/_extends";
import { useTheme } from "next-themes";
import { useMemo } from "react";
import { create } from "zustand";
export const useConfig = create((set)=>({
        config: {},
        setConfig: (newConfig)=>{
            if (typeof newConfig === "function") {
                set((state)=>({
                        config: _extends({}, newConfig(state.config))
                    }));
            } else {
                set(()=>({
                        config: _extends({}, newConfig)
                    }));
            }
        }
    }));
export function useResolvedTheme() {
    const theme = useTheme();
    const mode = useMemo(()=>{
        var _theme_resolvedTheme;
        return (_theme_resolvedTheme = theme.resolvedTheme) != null ? _theme_resolvedTheme : "light";
    }, [
        theme.resolvedTheme
    ]);
    return mode;
}
export function flattenStyles(config, theme) {
    var _config_fonts, _config_fonts1, _config_vars;
    var _config_radius, _config_fonts_body, _config_fonts_heading;
    const base = {
        "--radius": `${(_config_radius = config.radius) != null ? _config_radius : 0.5}rem`,
        "--font-body": (_config_fonts_body = (_config_fonts = config.fonts) == null ? void 0 : _config_fonts.body) != null ? _config_fonts_body : "var(--font-inter)",
        "--font-heading": (_config_fonts_heading = (_config_fonts1 = config.fonts) == null ? void 0 : _config_fonts1.heading) != null ? _config_fonts_heading : "var(--font-inter)"
    };
    if ((_config_vars = config.vars) == null ? void 0 : _config_vars[theme]) {
        return Object.entries(config.vars[theme]).reduce((prev, [key, value])=>{
            return _extends({}, prev, {
                [`--${key}`]: value
            });
        }, base);
    }
    return base;
}
